import "../KYC/KYC.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate, useParams } from "react-router-dom";
import { FiUpload } from "react-icons/fi";
import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap-button-loader";
import { getAllData, putData } from "../../Api/api";
const EditUser = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [aadharCard, setAadharCard] = useState("");
  const [landlineNumber, setLandlineNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [residenceAddress, setResidenceAddress] = useState("");
  const [firstLineAddress, setFirstLineAddress] = useState("");
  const [secondLineAddress, setSecondLineAddress] = useState("");
  const [district, setDistrict] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [shopTurnOver, setShopTurnOver] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [shopName, setShopName] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [totalStaff, setTotalStaff] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [shopAddress, setShopAddress] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [shopLocation, setShopLocation] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const [shopImage, setShopImage] = useState(null);
  const [adhaarImage, setAdhaarImage] = useState(null);
  const [gstCertificate, setGstCertificate] = useState(null);
  const [panCardImage, setPanCardImage] = useState(null);
  const panCardInputRef = useRef(null);
  const adhaarInputRef = useRef(null);
  const gstInputRef = useRef(null);
  const shopInputRef = useRef(null);
  const profilePicInputRef = useRef(null);
  const handleFileChange = (e, setState) => {
    const file = e.target.files[0];
    setState(file);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = new FormData();
    if (email) payload.append("email", email);
    if (password) payload.append("password", password);
    if (firstName) payload.append("firstName", firstName);
    if (lastName) payload.append("lastName", lastName);
    if (aadharCard) payload.append("aadharCard", aadharCard);
    if (landlineNumber) payload.append("landlineNumber", landlineNumber);
    if (mobileNumber) payload.append("mobileNumber", mobileNumber);
    if (residenceAddress) payload.append("residenceAddress", residenceAddress);
    if (firstLineAddress) payload.append("firstLineAddress", firstLineAddress);
    if (secondLineAddress)
      payload.append("secondLineAddress", secondLineAddress);
    if (district) payload.append("district", district);
    if (pinCode) payload.append("pinCode", pinCode);
    if (country) payload.append("country", country);
    if (state) payload.append("state", state);
    if (shopTurnOver) payload.append("shopTurnOver", shopTurnOver);
    if (gstNo) payload.append("gstNo", gstNo);
    if (shopName) payload.append("shopName", shopName);
    if (panNumber) payload.append("panNumber", panNumber);
    if (totalStaff) payload.append("totalStaff", totalStaff);
    if (fatherName) payload.append("fatherName", fatherName);
    if (shopAddress) payload.append("shopLocation", shopAddress);
    if (accountHolderName)
      payload.append("accountHolderName", accountHolderName);
    if (accountNumber) payload.append("bankAccountNumber", accountNumber);
    if (profilePic) payload.append("profilePic", profilePic);
    if (shopImage) payload.append("shopImage", shopImage);
    if (adhaarImage) payload.append("adhaarImage", adhaarImage);
    if (gstCertificate) payload.append("gstCertificate", gstCertificate);
    if (panCardImage) payload.append("panCardImage", panCardImage);
    putData({
      url: "admin/updateShopkeeperProfile/" + params.id,
      payload,
      setIsLoading,
      additionalFunc: () => navigate("/users"),
    });
  };
  const params = useParams();

  const getUserData = () => {
    getAllData({
      url: `admin/getUserById/${params.id}`,
      setData: setUserData,
      setIsLoading: setIsLoading,
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (userData?.data?.user) {
      setFirstName(userData?.data?.user?.firstName);
      setLastName(userData?.data?.user?.lastName);
      setFatherName(userData?.data?.user?.fatherName);
      setShopName(userData?.data?.user?.shopName);
      setAccountHolderName(userData?.data?.user?.accountHolderName);
      setAccountNumber(userData?.data?.user?.bankAccountNumber);
      setShopAddress(userData?.data?.user?.shopLocation);
      setTotalStaff(userData?.data?.user?.totalStaff);
      setShopTurnOver(userData?.data?.user?.shopTurnOver);
      setGstNo(userData?.data?.user?.gstNo);
      setPanNumber(userData?.data?.user?.panNumber);
      setShopLocation(userData?.data?.user?.shopLocation);

      setShopAddress(userData?.data?.user?.shopLocation);

      setProfilePic(userData?.data?.user?.profilePic);
      setShopImage(userData?.data?.user?.shopImage);
      setAdhaarImage(userData?.data?.user?.adhaarImage);
      setGstCertificate(userData?.data?.user?.gstCertificate);
      setPanCardImage(userData?.data?.user?.panCardImage);

      setMobileNumber(userData?.data?.user?.mobileNumber);
      setLandlineNumber(userData?.data?.user?.landlineNumber);
      setResidenceAddress(userData?.data?.user?.residenceAddress);
      setFirstLineAddress(userData?.data?.user?.firstLineAddress);
      setSecondLineAddress(userData?.data?.user?.secondLineAddress);
      setDistrict(userData?.data?.user?.district);
      setPinCode(userData?.data?.user?.pinCode);
      setCountry(userData?.data?.user?.country);
      setState(userData?.data?.user?.state);

      setEmail(userData?.data?.user?.email);
    }
  }, [userData]);
  return (
    <>
      <div className="userdetails">
        <div className="userdetails1">
          <div className="userdetails2">
            <div className="userdetails3">
              <div className="userdetails5"></div>
            </div>
          </div>
          <div className="userdetails7">
            <div className="userdetails8">
              <label htmlFor="">First Name</label>
              <input
                type="text"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Father's Name</label>
              <input
                type="text"
                name="lastName"
                value={fatherName}
                onChange={(e) => setFatherName(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Mobile Number</label>
              <input
                type="text"
                name="mobileNumber"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Email</label>
              <input
                type="text"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Shop Address</label>
              <input
                type="text"
                name="secondLineAddress"
                value={shopAddress}
                onChange={(e) => setShopAddress(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Aadhar Card Number</label>
              <input
                type="text"
                name="aadharCard"
                value={aadharCard}
                onChange={(e) => setAadharCard(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">PAN Number</label>
              <input
                type="text"
                name="panNumber"
                value={panNumber}
                onChange={(e) => setPanNumber(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">GST No.</label>
              <input
                type="text"
                name="gstNo"
                value={gstNo}
                onChange={(e) => setGstNo(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Bank Name</label>
              <input
                type="text"
                name="landlineNumber"
                value={landlineNumber}
                onChange={(e) => setLandlineNumber(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Account Holder Name</label>
              <input
                type="text"
                name="acc holder name"
                value={accountHolderName}
                onChange={(e) => setAccountHolderName(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Bank Account Number</label>
              <input
                type="text"
                name="Bank Account Number"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Residence Address</label>
              <input
                type="text"
                name="residenceAddress"
                value={residenceAddress}
                onChange={(e) => setResidenceAddress(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">District</label>
              <input
                type="text"
                name="district"
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Pin Code</label>
              <input
                type="text"
                name="pinCode"
                value={pinCode}
                onChange={(e) => setPinCode(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Country</label>
              <input
                type="text"
                name="country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">State</label>
              <input
                type="text"
                name="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Shop Turn Over</label>
              <input
                type="text"
                name="shopTurnOver"
                value={shopTurnOver}
                onChange={(e) => setShopTurnOver(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Shop Name</label>
              <input
                type="text"
                name="shopName"
                value={shopName}
                onChange={(e) => setShopName(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Shop Location</label>
              <input
                type="text"
                name="shopLocation"
                value={shopLocation}
                onChange={(e) => setShopLocation(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Total Staff</label>
              <input
                type="text"
                name="totalStaff"
                value={totalStaff}
                onChange={(e) => setTotalStaff(e.target.value)}
              />
            </div>
            <div className="userdetails8">
              <label htmlFor="">Password</label>
              <input
                type="text"
                name="totalStaff"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="kyc11">
            <div className="kyc2">
              <div className="kyc3">
                <label htmlFor="panCard">Pan Card Upload</label>
                <input
                  type="file"
                  id="panCard"
                  ref={panCardInputRef}
                  accept=".pdf"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, setPanCardImage)}
                />
                <div
                  className="kyc4"
                  onClick={() => panCardInputRef.current.click()}
                >
                  <FiUpload color="#000000" size={20} />
                  <p>{panCardImage ? panCardImage.name : "Upload PDF"}</p>
                </div>
              </div>
            </div>
            <div className="kyc2">
              <div className="kyc3">
                <label htmlFor="adhaarCard">Aadhar Card Upload</label>
                <input
                  type="file"
                  id="adhaarCard"
                  ref={adhaarInputRef}
                  accept=".pdf"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, setAdhaarImage)}
                />
                <div
                  className="kyc4"
                  onClick={() => adhaarInputRef.current.click()}
                >
                  <FiUpload color="#000000" size={20} />
                  <p>{adhaarImage ? adhaarImage.name : "Upload PDF"}</p>
                </div>
              </div>
            </div>
            <div className="kyc2">
              <div className="kyc3">
                <label htmlFor="gstCertificate">GST Certificate Upload</label>
                <input
                  type="file"
                  id="gstCertificate"
                  ref={gstInputRef}
                  accept=".pdf"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, setGstCertificate)}
                />
                <div
                  className="kyc4"
                  onClick={() => gstInputRef.current.click()}
                >
                  <FiUpload color="#000000" size={20} />
                  <p>{gstCertificate ? gstCertificate.name : "Upload PDF"}</p>
                </div>
              </div>
            </div>
            <div className="kyc2">
              <div className="kyc3">
                <label htmlFor="shopImage">Shop Photo Upload</label>
                <input
                  type="file"
                  id="shopImage"
                  ref={shopInputRef}
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, setShopImage)}
                />
                <div
                  className="kyc4"
                  onClick={() => shopInputRef.current.click()}
                >
                  <FiUpload color="#000000" size={20} />
                  <p>{shopImage ? shopImage.name : "Upload Image"}</p>
                </div>
              </div>
            </div>
            <div className="kyc2">
              <div className="kyc3">
                <label htmlFor="profilePic">Customer Photo Upload</label>
                <input
                  type="file"
                  id="profilePic"
                  ref={profilePicInputRef}
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, setProfilePic)}
                />
                <div
                  className="kyc4"
                  onClick={() => profilePicInputRef.current.click()}
                >
                  <FiUpload color="#000000" size={20} />
                  <p>{profilePic ? profilePic.name : "Upload Image"}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="kyc5">
            <button onClick={() => navigate("/kyc")}>Back</button>
            <Button onClick={handleSubmit} loading={isLoading}>
              Edit User
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default HOC(EditUser);
