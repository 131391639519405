import "./FraudCustomer.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosSearch } from "react-icons/io";
import { MdRemoveRedEye } from "react-icons/md";
import { useEffect, useState } from "react";
import { getAllData } from "../../Api/api";

const FraudCustomer = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(data?.data?.totalDocs || 0);
  const [currentPage, setCurrentPage] = useState(data?.data?.page || 0);
  const [totalPage, setTotalPage] = useState(data?.data?.totalPages || 0);
  const [search, setSearch] = useState("");

  const getData = () => {
    const params = {};
    if (search) params.search = search;
    getAllData({
      url: `admin/getFraudUser`,
      setData: setData,
      setIsLoading: setIsLoading,
    });
  };
  useEffect(() => {
    getData();
  }, [search, currentPage, currentPage]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };
  return (
    <>
      <div className="totalusers">
        <div className="totalusers1">
          <div className="totalusers4">
            <div className="totalusers5">
              <IoIosArrowBack
                color="#202224"
                size={25}
                onClick={() => navigate(-1)}
              />
              <h6>Fraud Customer List Report</h6>
            </div>
            <div className="totalusers6">
              <div className="totalusers7">
                <IoIosSearch color="#D5D5D5" size={20} />
                <input
                  type="search"
                  placeholder="Search by Date, ID or software"
                />
              </div>
              {/* <div className="totalusers8">
                <select name="" id="">
                  <option value="">Filter</option>
                </select>
              </div> */}
            </div>
          </div>

          <div className="totalusers9">
            <div className="totalusers10">
              <table>
                <thead>
                  <tr>
                    <th>Loan ID</th>
                    <th>Shop ID</th>
                    <th>Customer Name</th>
                    <th>Loan Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.docs?.map((data) => (
                    <tr key={data.id}>
                      <td>{data?.applicantId?.Id}</td>
                      <td>{data?.shopkeeperId?.Id}</td>
                      <td>
                        {(data.firstName || "") + " " + (data.lastName || "")}
                      </td>
                      <td>{data.totalAmount}</td>
                      <td className="totalusers11">
                        <MdRemoveRedEye
                          color="#1C1B1F"
                          size={20}
                          onClick={() =>
                            navigate(`/user-detail/${data?._id}`, {
                              state: data,
                            })
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <div>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={totalPage}
            onPageChange={handlePageClick}
            containerClassName={paginationStyles.pagination}
            previousLinkClassName={paginationStyles.paginationLink}
            nextLinkClassName={paginationStyles.paginationLink}
            disabledClassName={paginationStyles.paginationDisabled}
            activeClassName={paginationStyles.paginationActive}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
          />
        </div> */}
      </div>
    </>
  );
};

export default HOC(FraudCustomer);
