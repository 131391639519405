import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { LuUpload } from "react-icons/lu";
import {postData} from "../../Api/api";
import HOC from "../../Components/HOC/HOC";
import Button from "react-bootstrap-button-loader";

const PushNotification = () => {
  const navigate = useNavigate();
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationImage, setNotificationImage] = useState(null);
  const [publishStartDate, setPublishStartDate] = useState("");
  const [publishEndDate, setPublishEndDate] = useState("");
  const [notificationFor, setNotificationFor] = useState("shopkeeper");
  const [bannerImage, setBannerImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleBannerUpload = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/svg+xml")
    ) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBannerImage(reader.result);
        setNotificationImage(file);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please upload an image file (SVG, PNG, JPG, or JPEG).");
    }
  };

  const handleSubmit = async () => {
    const payload = new FormData();
    if (notificationTitle) payload.append("title", notificationTitle);
    if (notificationImage) payload.append("image", notificationImage);
    if (publishStartDate) payload.append("startDate", publishStartDate);
    if (publishEndDate) payload.append("endDate", publishEndDate);
    if (notificationFor) payload.append("reciver", notificationFor);

    setIsLoading(true);
    const additionalFunction = () => {
      setNotificationTitle("");
      setNotificationImage(null);
      setPublishStartDate("");
      setPublishEndDate("");
      setNotificationFor("shopkeeper");
      setBannerImage(null);
      setIsLoading(false);
    };
    try {
      await postData({
        url: "admin/addNotification",
        payload,
        setIsLoading,
        additionalFunc: () => additionalFunction(),
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error submitting the notification:", error);
    }
  };

  return (
    <div className="totalusers">
      <div className="totalusers1">
        <div className="totalusers4">
          <div className="totalusers5">
            <IoIosArrowBack
              color="#202224"
              size={25}
              onClick={() => navigate(-1)}
            />
            <h6>Push notification</h6>
          </div>
          <div className="notification">
            <div
              className="notification1"
              onClick={() => navigate("/notifications")}
            >
              <p>Notification List</p>
            </div>
          </div>
        </div>

        <div className="notification2">
          <div className="notification3">
            <div className="notification4">
              <label htmlFor="">Title</label>
              <input
                type="text"
                placeholder="write title"
                onChange={(e) => setNotificationTitle(e.target.value)}
                value={notificationTitle}
              />
            </div>
            <div className="notification4">
              <label htmlFor="bannerUpload">Notification Banner</label>
              <div className="notification5">
                <input
                  type="file"
                  accept=".svg, .png, .jpg, .jpeg"
                  onChange={handleBannerUpload}
                  style={{ display: "none" }}
                  id="bannerUpload"
                />
                <label htmlFor="bannerUpload" className="grid items-center">
                  {!bannerImage && (
                    <>
                      <LuUpload
                        size={50}
                        color="#7F898B"
                        className="uploadIcon"
                      />
                      <p>upload notification banner image</p>
                    </>
                  )}
                </label>
                {bannerImage && (
                  <img
                    src={bannerImage}
                    alt="Notification Banner Preview"
                    className="banner-preview"
                    onClick={() => setBannerImage(null)}
                  />
                )}
              </div>
            </div>
            <div className="notification6">
              <div className="notification4">
                <label htmlFor="">Publish date</label>
                <input
                  type="date"
                  onChange={(e) => setPublishStartDate(e.target.value)}
                  value={publishStartDate}
                />
              </div>
              <div className="notification4">
                <label htmlFor="">Publish End</label>
                <input
                  type="date"
                  onChange={(e) => setPublishEndDate(e.target.value)}
                  value={publishEndDate}
                />
              </div>
            </div>
            <div className="notification7">
              <label htmlFor="">Select Ad Panel</label>
              <div className="notification8">
                <input
                  type="radio"
                  onChange={() => setNotificationFor("shopkeeper")}
                  checked={notificationFor === "shopkeeper"}
                  name="notificationFor"
                />
                <label htmlFor="">Shopkeeper</label>
              </div>
              <div className="notification8">
                <input
                  type="radio"
                  onChange={() => setNotificationFor("user ")}
                  checked={notificationFor === "user "}
                  name="notificationFor"
                />
                <label htmlFor="">Customer Application</label>
              </div>
            </div>

            <div className="notification9">
              <Button loading={isLoading} onClick={handleSubmit}>
                Add Notification
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(PushNotification);
