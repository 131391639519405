import "./Wallet.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosSearch } from "react-icons/io";
import { useEffect, useState } from "react";
import { getAllData } from "../../Api/api";

const Wallet = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(data?.data?.totalDocs || 0);
  const [currentPage, setCurrentPage] = useState(data?.data?.page || 0);
  const [totalPage, setTotalPage] = useState(data?.data?.totalPages || 0);
  const [search, setSearch] = useState("");
  const [kycVerified, setKycVerified] = useState("");

  const getData = () => {
    const params = {};
    if (search) params.search = search;

    if (kycVerified) params.kycVerified = kycVerified;

    getAllData({
      url: `admin/getPayment`,
      setData: setData,
      setIsLoading: setIsLoading,
      params: params,
    });
  };
  useEffect(() => {
    getData();
  }, [search, currentPage, kycVerified]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  return (
    <>
      <div className="totalusers">
        <div className="totalusers1">
          <div className="totalusers4">
            <div className="totalusers5">
              <IoIosArrowBack
                color="#202224"
                size={25}
                onClick={() => navigate(-1)}
              />
              <h6>Wallet</h6>
            </div>
            <div className="totalusers6">
              <div className="totalusers7">
                <IoIosSearch color="#D5D5D5" size={20} />
                <input
                  type="search"
                  placeholder="Search by Date, ID or software"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
              </div>
              {/* <div className="totalusers8">
                <Form.Select
                  value={kycVerified}
                  onChange={(e) => setKycVerified(e.target.value)}
                >
                  <option value="">All</option>
                  <option value="true">Verified</option>
                  <option value="false">Not Verified</option>
                </Form.Select>
              </div> */}
            </div>
          </div>

          <div className="totalusers9">
            <div className="totalusers10">
              <table>
                <thead>
                  <tr>
                    <th>Shop ID</th>
                    <th>Shop Name</th>
                    <th>Owner Name</th>
                    <th>Retail Balance</th>
                    {/* <th>Virtual Balance</th> */}
                    <th>Transaction ID</th>
                    <th>Paid Payment</th>
                    <th>Pending Payment</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.docs?.map((data) => (
                    <tr key={data.id}>
                      <td>{data.shopkeeperId?.Id}</td>
                      <td>{data?.shopkeeperId?.ShopName}</td>
                      <td>
                        {(data.shopkeeperId?.firstName || "") +
                          " " +
                          (data?.shopkeeperId?.lastName || "")}
                      </td>
                      <td>{data.RetailBalance}Pending</td>
                      {/* <td>{data?.shopkeeperId?.virtualBalance}</td> */}
                      <td>{data.transactionId}</td>
                      <td>{data?.shopkeeperId?.paidPayment}</td>
                      <td>{data?.shopkeeperId?.pendingPayment}</td>
                      {/* <td className="totalusers11">
                        <MdRemoveRedEye
                          color="#1C1B1F"
                          size={20}
                          onClick={() =>
                            navigate(
                              `/manage_wallet/${data?.shopkeeperId?._id}`
                            )
                          }
                        />
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <div>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={totalPage}
            onPageChange={handlePageClick}
            containerClassName={paginationStyles.pagination}
            previousLinkClassName={paginationStyles.paginationLink}
            nextLinkClassName={paginationStyles.paginationLink}
            disabledClassName={paginationStyles.paginationDisabled}
            activeClassName={paginationStyles.paginationActive}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
          />
        </div> */}
      </div>
    </>
  );
};

export default HOC(Wallet);
