import "./ProfileUpdate.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import img from "../../Img/img8.png";
import { useEffect, useState } from "react";
import { getAllData, putData } from "../../Api/api";
import Button from "react-bootstrap-button-loader";
import { showNotification } from "../../Api/ImportantComponents";
const ProfileUpdate = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [country, setCountry] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [search, setSearch] = useState("");

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImageFile(event.target.files[0]);
    if (
      file &&
      (file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/svg+xml")
    ) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      showNotification(
        "error",
        "Please upload an image file (SVG, PNG, JPG, or JPEG)."
      );
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (
      file &&
      (file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/svg+xml")
    ) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please upload an image file (SVG, PNG, JPG, or JPEG).");
    }
  };
   
  const getData = () => {
    getAllData({
      url: `admin/getProfile`,
      setData: setData,
    });
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data?.data) {
      console.log(data?.data);
      setImagePreview(data?.data?.profilePic || img);

      setFirstName(data?.data?.firstName);
      setLastName(data?.data?.lastName);
      setEmail(data?.data?.email);
      setRole(data?.data?.role);
      setCountry(data?.data?.country);
    }
  }, [data]);

  const handleSubmit = () => {
    const payload = new FormData();
    if (firstName) payload.append("firstName", firstName);
    if (lastName) payload.append("lastName", lastName);
    if (email) payload.append("email", email);
    // if (role) payload.append("role", role);
    if (country) payload.append("country", country);
    if (imageFile) payload.append("image", imageFile);

    putData({
      url: `admin/updateProfile`,
      payload: payload,
      setIsLoading,
      additionalFunc: () => getData(),
    });
  };

  return (
    <>
      <div className="totalusers">
        <div className="totalusers1">
          <div className="totalusers4">
            <div className="totalusers5">
              <IoIosArrowBack
                color="#202224"
                size={25}
                onClick={() => navigate(-1)}
              />
              <h6>Profile Update</h6>
            </div>
          </div>

          <div className="profileupdate">
            <div className="profileupdate1">
              <div className="profileupdate2">
                <div className="profileupdate3">
                  <label htmlFor="">Name</label>
                  <label htmlFor="">Email</label>
                  <label htmlFor="">
                    Your Photo <br />{" "}
                    <p>This photo will be displayed on your profile</p>
                  </label>
                  <label htmlFor="">Role</label>
                  <label htmlFor="">Country</label>
                </div>

                <div className="profileupdate4">
                  <div className="profileupdate5">
                    <input
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      type="text"
                      placeholder="John"
                    />
                    <input
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      type="text"
                      placeholder="Deo"
                    />
                  </div>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="johndeo@gmail.com"
                  />

                  <div className="profileupdate6">
                    <div className="profileupdate7">
                      <img src={imagePreview || img} alt="Profile Preview" />
                    </div>
                    <div className="profileupdate8">
                      <div
                        className="profileupdate9"
                        onDragOver={(event) => event.preventDefault()}
                        onDrop={handleDrop}
                      >
                        <input
                          type="file"
                          accept=".svg, .png, .jpg, .jpeg"
                          onChange={handleImageUpload}
                          style={{ display: "none" }}
                          id="fileUpload"
                        />
                        <label htmlFor="fileUpload">
                          <p>
                            Click to upload or <span>drag & drop</span>
                            <br />
                            (SVG, PNG, JPG and JPEG)
                          </p>
                        </label>
                      </div>
                    </div>
                  </div>
                  <input
                    type="text"
                    placeholder="Staff"
                    value={"Admin"}
                    disabled
                    // onChange={(e) => setRole(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="India"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="kyc5">
              <Button onClick={() => navigate(-1)}>Cancel</Button>
              <button
                loading={isLoading}
                disabled={isLoading}
                onClick={() => handleSubmit()}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HOC(ProfileUpdate);
