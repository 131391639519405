import "./Notifications.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosSearch } from "react-icons/io";
import { MdRemoveRedEye } from "react-icons/md";
import ReactPaginate from "react-paginate";
import paginationStyles from "../Paganiation/pagination.module.css";
import { useEffect, useState } from "react";
import { formatDate, getAllData } from "../../Api/api";
import { PaginatedItems } from "../../Api/ImportantComponents";

const Notifications = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(data?.data?.totalDocs || 0);
  const [currentPage, setCurrentPage] = useState(data?.data?.page || 0);
  const [totalPage, setTotalPage] = useState(data?.data?.totalPages || 0);
  const [search, setSearch] = useState("");

  const getData = () => { const params = {
    limit: 999999999,
  };
  if (search) params.search = search;
    getAllData({
      url: `admin/getNotification`,
      setData: setData,
      setIsLoading: setIsLoading,
      params: params,
    });
  };
  useEffect(() => {
    getData();
  }, [search, currentPage, currentPage]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    console.log(event, "event");
  };

  return (
    <>
      <div className="totalusers">
        <div className="totalusers1">
          <div className="totalusers4">
            <div className="totalusers5">
              <IoIosArrowBack
                color="#202224"
                size={25}
                onClick={() => navigate(-1)}
              />
              <h6>Notification List</h6>
            </div>
            <div className="totalusers6">
              <div className="totalusers7">
                <IoIosSearch color="#D5D5D5" size={20} />
                <input
                  type="search"
                  placeholder="Search by Date, ID or software"
                />
              </div>
              <div className="totalusers8">
                <select name="" id="">
                  <option value="">Filter</option>
                </select>
              </div>
            </div>
          </div>

          <div className="totalusers9">
            <div className="totalusers10">
              <table>
                <thead>
                  <tr>
                    <th>Notification Title</th>
                    <th>Banner</th>
                    <th>Publish Date</th>
                    <th>End Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.docs?.map((data) => (
                    <tr key={data._id}>
                      <td>{data.title}</td>
                      <td>
                        {" "}
                        <img
                          className="notification_image"
                          src={data.image}
                          alt=""
                        />
                      </td>
                      <td>{formatDate(data.startDate)}</td>
                      <td>{formatDate(data.endDate)}</td>
                      <td>
                        <MdRemoveRedEye color="#1C1B1F" size={20} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={totalPage}
            onPageChange={(e) => console.log(e, "event")}
            containerClassName={paginationStyles.pagination}
            previousLinkClassName={paginationStyles.paginationLink}
            nextLinkClassName={paginationStyles.paginationLink}
            disabledClassName={paginationStyles.paginationDisabled}
            activeClassName={paginationStyles.paginationActive}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
          />
           <PaginatedItems items={data?.data?.docs} itemsPerPage={4} updateCurrentPage={setCurrentPage} />
        </div>
      </div>
    </>
  );
};

export default HOC(Notifications);
