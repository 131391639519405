import "./Login.css";
import { useNavigate } from "react-router-dom";
import { LuUser2 } from "react-icons/lu";
import { IoArrowForward } from "react-icons/io5";
import { useState } from "react";
import { showNotification } from "../../Api/ImportantComponents";
import { forgotPasswordHandler } from "../../Api/api";

import Button from "react-bootstrap-button-loader";

const Verification = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    if (!email) {
      showNotification({ type: "error", message: "Email is required" });
      return;
    }
    forgotPasswordHandler({
      payload: { email },
      navigate,
      nextPage: () => navigate("/verificationcode"),
      setIsLoading,
    });
  };
  return (
    <>
      <div className="login">
        <div className="login1">
          <div className="login2">
            <div className="login33">
              <div className="login3">
                <LuUser2 color="#1B85F3" size={30} />
              </div>
            </div>

            <div className="login4">
              <h5>Verify number or email</h5>
              <p>
                You’ll receive a verification code on your registered mobile or
                email.
              </p>
            </div>

            <div className="login5">
              <input type="text" placeholder="Mobile" />
              <p>or</p>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
            </div>
            <div className="login9">
              <Button
                loading={isLoading}
                disabled={isLoading}
                onClick={() => handleSubmit()}
              >
                NEXT <IoArrowForward size={25} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verification;
