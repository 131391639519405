import { useEffect, useState } from "react";
import "./Dashboard.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { IoIosArrowForward } from "react-icons/io";
import ReactApexChart from "react-apexcharts";
import { getAllData } from "../../Api/api";
const Dashboard = () => {
  const percentage = 70;
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [setIsLoading] = useState(false);
  const [series] = useState([
    {
      name: "Sales",
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      color: "#2196F3",
    },

    {
      name: "Collection",
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      color: "#FFA500",
    },
  ]);

  const [options] = useState({
    chart: {
      type: "bar",
      height: 350,
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
      },
    },

    dataLabels: {
      enabled: false,
    },

    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },

    yaxis: {
      title: {
        text: "Sales",
      },
    },

    fill: {
      opacity: 1,
    },

    legend: {
      show: true,
      position: "top",
    },

    tooltip: {
      y: {
        formatter: function (val) {
          return "₹ " + val + " Amount";
        },
      },
    },
  });

  const [setSeries,setSeries1] = useState({
    name: "Device",
    data: Array.from({ length: 10 }, () => Math.floor(Math.random() * 100)),
    color: "#F44336",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const newSeries = [
        {
          data: Array.from({ length: 10 }, () =>
            Math.floor(Math.random() * 100)
          ),
        },
      ];

      setSeries1(newSeries);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  const getData = async () => {
    getAllData({ url: "admin/dashboard", setData, setIsLoading });
  };
  useEffect(() => {
    getData();
  }, []);
  const dashboardData = data?.data;
  return (
    <>
      <div className="dashboard">
        <div className="dashboard1">
          <div className="dashboard2">
            <div className="dashboard3" onClick={() => navigate("/users")}>
              <div className="dashboard4">
                <CircularProgressbar
                  value={percentage}
                  strokeWidth={50}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: "#709288",
                    backgroundColor: "#FFFFFF",
                    trailColor: "#d6d6d6",
                  })}
                />
              </div>
              <div className="dashboard5">
                <p>Total Collections</p>
                <h6>
                  {dashboardData?.totalUser}
                  {}
                  <IoIosArrowForward />
                </h6>
              </div>
            </div>
            <div className="dashboard3" onClick={() => navigate("/kyc")}>
              <div className="dashboard4">
                <CircularProgressbar
                  value={percentage}
                  strokeWidth={50}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: "#709288",
                    backgroundColor: "#FFFFFF",
                    trailColor: "#d6d6d6",
                  })}
                />
              </div>
              <div className="dashboard5">
                <p>Paid Amount</p>
                <h6>
                  {dashboardData?.pendingKyc}
                  {}
                  <IoIosArrowForward />
                </h6>
              </div>
            </div>
            <div className="dashboard3" onClick={() => navigate("/payment")}>
              <div className="dashboard4">
                <CircularProgressbar
                  value={percentage}
                  strokeWidth={50}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: "#709288",
                    backgroundColor: "#FFFFFF",
                    trailColor: "#d6d6d6",
                  })}
                />
              </div>
              <div className="dashboard5">
                <p>Pending Amount</p>
                <h6>
                  {dashboardData?.pendingPayment}
                  {}
                  <IoIosArrowForward />
                </h6>
              </div>
            </div>
          </div>
          <div className="dashboard6">
            <div className="dashboard7">
              <h3>Anual Report</h3>
              {/* <div className="dashboard8">
                 <select name="" id="">
                   <option value="">All State</option>
                 </select>
                 <select name="" id="">
                   <option value="">Month</option>
                 </select>
                </div> */}
            </div>
            <div className="dashboard9">
              <ReactApexChart
                options={options}
                series={series}
                type="bar"
                height={350}
              />
            </div>
          </div>
          {/* <div className="dashboard6">
             <div className="dashboard7">
               <h3>Payment Report</h3>
               <div className="dashboard8">
                 <select name="" id="">
                   <option value="">Month</option>
                 </select>
               </div>
             </div>
             <div className="dashboard9" onClick={() => navigate("/payment")}>
               <ReactApexChart
                 options={options1}
                 series={[series1, series2, series3]}
                 type="line"
                 height={350}
               />
             </div>
            </div>
            <div className="dashboard6">
             <div className="dashboard7">
               <h3>Device Revenue</h3>
               <div className="dashboard8">
                 <select name="" id="">
                   <option value="">Month</option>
                 </select>
               </div>
             </div>
             <div className="dashboard9">
               <ReactApexChart
                 options={options1}
                 series={[series1, series2, series3]}
                 type="line"
                 height={350}
               />
             </div>
            </div> */}
        </div>
      </div>
    </>
  );
};
export default HOC(Dashboard);
