import "./Payment.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosSearch } from "react-icons/io";
import { useEffect, useState } from "react";
import { formatDate, getAllData } from "../../Api/api";

const Payment = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(data?.data?.totalDocs || 0);
  const [currentPage, setCurrentPage] = useState(data?.data?.page || 0);
  const [totalPage, setTotalPage] = useState(data?.data?.totalPages || 0);
  const [search, setSearch] = useState("");

  const getData = () => {
    const params = {};
    if (search) params.search = search;
    getAllData({
      url: `admin/getPayment`,
      setData: setData,
      setIsLoading: setIsLoading,
      params: params,
    });
  };
  useEffect(() => {
    getData();
  }, [search, currentPage, currentPage]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div className="kyc7">
        <p>Bank Server Issue !</p>
      </div>
    </Tooltip>
  );
  return (
    <>
      <div className="totalusers">
        <div className="totalusers1">
          <div className="totalusers4">
            <div className="totalusers5">
              <IoIosArrowBack
                color="#202224"
                size={25}
                onClick={() => navigate(-1)}
              />
              <h6>Payment</h6>
            </div>
            <div className="totalusers6">
              {/* <div className="totalusers7">
                <IoIosSearch color="#D5D5D5" size={20} />
                <input
                  type="search"
                  placeholder="Search by Date, ID or software"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
              </div> */}
              {/* <div className="totalusers8">
                <select name="" id="">
                  <option value="">Filter</option>
                </select>
              </div> */}
            </div>
          </div>

          <div className="totalusers9">
            <div className="totalusers10">
              <table>
                <thead>
                  <tr>
                    <th>Payment Date</th>
                    <th>Loan ID</th>
                    <th>Shop ID</th>
                    <th>Customer Name</th>
                    <th>Amount</th>
                    <th>Transaction Id</th>
                    <th>Payment</th>
                    <th>Pay Mode</th>
                    {/* <th>Status</th> */}
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.docs?.map((data) => (
                    <tr key={data.id}>
                      <td>{formatDate(data.createdAt)}</td>
                      <td>{data.loanApplicationId?.Id}</td>
                      <td>{data?.shopkeeperId?.Id}</td>
                      <td>
                        {(data.applicantId?.firstName || "") +
                          " " +
                          (data.applicantId?.lastName || "")}
                      </td>
                      <td>{data.emiAmount}</td>
                      <td>{data.transactionId}</td>
                      <td>
                        <div className="kyc">
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                          >
                            <div
                              className="kyc1"
                              style={{
                                backgroundColor:
                                  data.loanInstallmentId?.paymentStatus ===
                                  "paid"
                                    ? "#00B69B"
                                    : data.Payment === "Failed"
                                    ? "#FFCB6A"
                                    : "#E57676",
                              }}
                            >
                              {data.loanInstallmentId?.paymentStatus}
                            </div>
                          </OverlayTrigger>
                        </div>
                      </td>
                      <td>{data.paymentMode}</td>
                      {/* <td>
                       
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <div>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={totalPage}
            onPageChange={handlePageClick}
            containerClassName={paginationStyles.pagination}
            previousLinkClassName={paginationStyles.paginationLink}
            nextLinkClassName={paginationStyles.paginationLink}
            disabledClassName={paginationStyles.paginationDisabled}
            activeClassName={paginationStyles.paginationActive}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
          />
        </div> */}
      </div>
    </>
  );
};

export default HOC(Payment);
