import { toast } from "sonner";
import ReactPaginate from "react-paginate";
import { useState } from "react";
// import paginationStyles from "../Paganiation/pagination.module.css";
import paginationStyles from "../Pages/Paganiation/pagination.module.css";

export const showNotification = ({ type, message }) => {
  switch (type) {
    case "success":
      toast.success(message);
      break;
    case "error":
      toast.error(message);
      break;
    case "info":
      toast.info(message);
      break;
    case "warning":
      toast.warning(message);
      break;
    default:
      toast(message);
  }
};

export const showPromiseNotification = (type, message, isLoading) => {
  const promise = () =>
    new Promise((resolve, reject) =>
      setTimeout(() => {
        if (type === "success") {
          resolve({ name: message });
        } else {
          reject(new Error("Something went wrong"));
        }
      }, 2000)
    );

  toast.promise(promise, {
    loading: isLoading || "Loading...",
    success: (data) => {
      return `${data.name} toast has been added`;
    },
    error: "Error",
  });
};

export const buttonLoader = () => {
  return <div className="loader"></div>;
};

export const Items = ({ currentItems }) => {
  return (
    <div>
      {currentItems &&
        currentItems.map((item, index) => (
          <div key={index}>
            {item.firstName} {item.lastName}
          </div>
        ))}
    </div>
  );
};

export const PaginatedItems = ({ items, itemsPerPage, updateCurrentPage }) => {
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items?.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
    updateCurrentPage(event.selected + 1);
  };

  return (
    <>
      <Items currentItems={currentItems} />
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName={paginationStyles.pagination}
        previousLinkClassName={paginationStyles.paginationLink}
        nextLinkClassName={paginationStyles.paginationLink}
        disabledClassName={paginationStyles.paginationDisabled}
        activeClassName={paginationStyles.paginationActive}
      />
    </>
  );
};
