import { useEffect, useState } from "react";
import "./KYC.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosSearch } from "react-icons/io";
import { MdRemoveRedEye } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import { getAllData } from "../../Api/api";

const KYC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(data?.data?.totalDocs || 0);
  const [currentPage, setCurrentPage] = useState(data?.data?.page || 0);
  const [totalPage, setTotalPage] = useState(data?.data?.totalPages || 0);
  const [search, setSearch] = useState("");
  const [filterUsers, setFilterUsers] = useState("All");

  const getData = () => {
    const params = {};
    if (search) params.search = search;
    if (filterUsers) params.status = filterUsers;
    getAllData({
      url: `admin/getShopkeeper`,
      setData: setData,
      setIsLoading: setIsLoading,
    });
  };
  useEffect(() => {
    getData();
  }, [search, currentPage, filterUsers]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div className="kyc7">
        <p>Your Aadhaar Is not Link your Bank Account !</p>
      </div>
    </Tooltip>
  );

  return (
    <>
      <div className="totalusers">
        <div className="totalusers1">
          <div className="totalusers2">
            {/* <div className="totalusers3" onClick={() => navigate("/createid")}>
              <h6>Create ID</h6>
            </div> */}
          </div>

          <div className="totalusers4">
            <div className="totalusers5">
              <IoIosArrowBack
                color="#202224"
                size={25}
                onClick={() => navigate(-1)}
              />
              <h6>KYC</h6>
            </div>
            <div className="totalusers6">
              <div className="totalusers7">
                <IoIosSearch color="#D5D5D5" size={20} />
                <input
                  type="search"
                  placeholder="Search by Date, ID or software"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
              </div>
              <div className="totalusers8">
                
              </div>
            </div>
          </div>

          <div className="totalusers9">
            <div className="totalusers10">
              <table>
                <thead>
                  <tr>
                    <th>Shop ID</th>
                    <th>Shop Name</th>
                    <th>Owner Name</th>
                    <th>Mobile No.</th>
                    <th>KYC Status</th>
                    <th>Payment Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.docs?.map((data, index) => (
                    <tr key={data.id}>
                      <td>{index + 1}</td>
                      <td>{data.shopName}</td>
                      <td>{data.firstName + " " + data.lastName}</td>
                      <td>{data.mobileNumber}</td>
                      <td>
                        <div className="kyc">
                          <OverlayTrigger
                            placement="top"
                            delay={{
                              show: data?.kycVerified === false ? 250 : 0,
                              hide: 400,
                            }}
                            overlay={renderTooltip}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className="kyc1"
                              style={{
                                backgroundColor:
                                  data.kycVerified === false
                                    ? "#E57676"
                                    : data.kycVerified === true
                                    ? "#00B69B"
                                    : "transparent",
                              }}
                            >
                              {data.kycVerified ? "Verified" : "Unverified"}
                            </div>
                          </OverlayTrigger>
                        </div>
                      </td>
                      <td>
                        <div className="kyc">
                          <div
                            className="kyc1"
                            style={{
                              backgroundColor:
                                data.pendingPayment > 0
                                  ? "#E57676"
                                  : data.pendingPayment === 0
                                  ? "#00B69B"
                                  : "transparent",
                            }}
                          >
                            {data.pendingPayment === 0 ? "Paid" : "UnPaid"}
                          </div>
                        </div>
                      </td>
                      <td className="totalusers11">
                        <MdRemoveRedEye
                          color="#1C1B1F"
                          size={20}
                          onClick={() => navigate("/kycdetails")}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <div>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={totalPage}
            onPageChange={handlePageClick}
            containerClassName={paginationStyles.pagination}
            previousLinkClassName={paginationStyles.paginationLink}
            nextLinkClassName={paginationStyles.paginationLink}
            disabledClassName={paginationStyles.paginationDisabled}
            activeClassName={paginationStyles.paginationActive}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
          />
        </div> */}
      </div>
    </>
  );
};

export default HOC(KYC);
