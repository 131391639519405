import "./Navbar.css";
import { useLocation, useNavigate } from "react-router-dom";
import img from "../../Img/img1.png";
import { RiArrowRightSLine } from "react-icons/ri";
import { BsFilterRight } from "react-icons/bs";
import { useEffect, useState } from "react";
import { getAllData } from "../../Api/api";

const Navbar = ({ toggleSidebar }) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const [currentText, setCurrentText] = useState("Dashboard");

  useEffect(() => {
    if (pathname === "/dashboard") {
      setCurrentText("Dashboard");
    } else if (pathname === "/users") {
      setCurrentText("Shop Owners");
    } else if (pathname === "/kyc") {
      setCurrentText("KYC");
    } else if (pathname === "/payment") {
      setCurrentText("Payment");
    } else if (pathname === "/wallet") {
      setCurrentText("Wallet");
    } else if (pathname === "/push_notification") {
      setCurrentText("Push Notification");
    } else if (pathname === "/shopkeeper_report") {
      setCurrentText("Shopkeeper Report");
    } else if (pathname === "/fraud_customer") {
      setCurrentText("Fraud Customer");
    } else if (pathname === "/settings") {
      setCurrentText("Settings");
    } else if (pathname === "/logout") {
      setCurrentText("Logout");
    }
  }, [pathname]);
  const [userData, setUserData] = useState({});

  const getData = () => {
    getAllData({
      url: `admin/getProfile`,
      setData: setUserData,
    });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="navbar111">
        <div className="navbar1">
          <div className="navbar2">
            <BsFilterRight onClick={toggleSidebar} size={25} color="#00000" />
            <h6>{currentText}</h6>
            {console.log(userData)}
          </div>
          <div className="navbar3" onClick={() => navigate("/profile")}>
            <div className="navbar4">
              <img src={userData?.data?.profilePic || img} alt="" />
            </div>
            <div className="navbar5">
              <p>{userData?.data?.firstName || ""}</p>
              <h6>
                Admin <RiArrowRightSLine color="#39434F" size={20} />
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
